<template>
	<div>
		<vs-prompt
			class="private-reply"
      :title="$t('Replies')"
      @accept="submitData()"
      :buttons-hidden="true"
      :is-valid="isFormValid"
      :disabled-outside="true"
      :active.sync="isModalActiveLocal">
      <!-- parent -->
      <div>
      <div class="flex items-center justify-between">
          <div class="flex items-center">
            <vs-avatar v-if="selectedItem.participant.avatar_thumbnail" size="40px" class="avatar-img" :src="selectedItem.participant.avatar_thumbnail" />
            <vs-avatar v-else :color="getColor(selectedItem && selectedItem.sender_name)" :text="getInitial(selectedItem && selectedItem.sender_name)" size="40px">
              <img src="@/assets/images/file-icons/default-img.jpg" class="responsive pointer" />
            </vs-avatar>
            <div>
              <div class="ml-2">{{ selectedItem && selectedItem.sender_name }}</div>
              <div class="ml-2 text-secondary text-xs">{{ getDate(selectedItem) }}</div>
            </div>
          </div>
          <!-- menu more button -->
          <div class="flex items-center" v-if="!isFromLive">
            <div class="flex items-center">
              <!-- favorite -->
              <vs-button @click="starredQuestion(selectedItem)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg v-if="selectedItem && !selectedItem.is_starred" class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star-outline</title>
                  <path fill="#1863CB" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
                </svg>
                <svg v-else class="w-8" fill="orange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>star</title><path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>
              </vs-button>
              <!-- highlight -->
              <vs-button @click="highlightQuestion(selectedItem)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg v-if="selectedItem && !selectedItem.is_highlight" class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-double-up</title><path  d="M7.41,18.41L6,17L12,11L18,17L16.59,18.41L12,13.83L7.41,18.41M7.41,12.41L6,11L12,5L18,11L16.59,12.41L12,7.83L7.41,12.41Z" /></svg>
                <svg v-else class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
              </vs-button>
              <!-- check -->
              <vs-button @click="publishedQuestion(selectedItem)" class="ml-2 btn-icon px-2 py-1" color="secondary" type="border">
                <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle-outline</title><path fill="#148b0E" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" /></svg>
              </vs-button>
            </div>
          <div>
            <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
                <div>
                    <feather-icon  icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item>
                    <div @click="editQuestion(selectedItem)" class="flex items-center question-menu">
                      <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg></div>
                      <div class="ml-2">{{ $t('Edit') }}</div>
                    </div>
                  </vs-dropdown-item>
                  <!-- archive -->
                  <vs-dropdown-item>
                    <div @click="archivedQustion(selectedItem)" class="flex items-center question-menu">
                      <div><svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>archive</title><path d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" /></svg></div>
                      <div class="ml-2">{{ selectedItem && selectedItem.is_archived ? $t('Unarchive') : $t('Archive') }}</div>
                    </div>
                  </vs-dropdown-item>
                  <!-- Delete -->
                  <vs-dropdown-item>
                    <div @click="deleteQuestion(selectedItem)" class="flex items-center question-menu">
                      <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></div>
                      <div class="ml-2">{{ $t('Delete') }}</div>
                    </div>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
      </div>
      </div>
      </div>
      <!-- content -->
      <div class="ml-2 mt-4">
        {{ selectedItem && selectedItem.name }}
      </div>

      <!-- replies -->
      <div class="mt-4 overflow-y-auto" style="max-height: 240px" v-if="selectedItem && selectedItem.replies && selectedItem.replies.length > 0">
        <div class="p-4" style="background-color: #f7f6f7; border-bottom: solid 1px rgb(229, 229, 229)" v-for="reply in selectedItem.replies" :key="reply.id">
          <div>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <vs-avatar v-if="reply.participant.avatar_thumbnail" size="40px" class="avatar-img" :src="reply.participant.avatar_thumbnail" />
                <vs-avatar v-else :color="getColor(reply && reply.sender_name)" :text="getInitial(reply && reply.sender_name)" size="40px">
                  <img src="@/assets/images/file-icons/default-img.jpg" class="responsive pointer" />
                </vs-avatar>
                <div>
                  <div class="ml-2">{{ reply && reply.sender_name }}</div>
                  <div class="ml-2 text-secondary text-xs">{{ getDate(reply) }}</div>
                </div>
              </div>
              <!-- menu more button -->
              <div class="flex items-center" v-if="!isFromLive">
              <div>
                <vs-dropdown class="dd-actions cursor-pointer" vs-trigger-click>
                    <div>
                        <feather-icon  icon="MoreVerticalIcon" svgClasses="h-7 w-7" />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div @click="editQuestion(reply)" class="flex items-center question-menu">
                          <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>pencil</title><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg></div>
                          <div class="ml-2">{{ $t('Edit') }}</div>
                        </div>
                      </vs-dropdown-item>
                      <!-- Delete -->
                      <vs-dropdown-item>
                        <div @click="deleteQuestion(reply)" class="flex items-center question-menu">
                          <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>delete</title><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></div>
                          <div class="ml-2">{{ $t('Delete') }}</div>
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            </div>
          </div>
          <!-- content -->
          <div class="ml-2 mt-4">
            {{ reply && reply.name }}
          </div>
        </div>
      </div>

      <!-- reply section -->
      <div class="ml-2 reply-section relative">
          <vs-textarea
              :label="$t('Type in your reply')"
              class="w-full input-private-reply"
              name="item-content"
              rows="4"
              @input="validateCharCount"
              v-model="dataPrivateReply"
          />
          <!-- checkbox email -->
          <div v-if="selectedItem && selectedItem.participant && selectedItem.participant.email" class="flex absolute bottom-0 right-0 mb-16" >
              <div>{{ $t('Reply to user\'s email')}}</div>
              <vs-checkbox v-model="dataIsReplyEmail"></vs-checkbox>
          </div>
          <!-- dropdown user on admin side -->
          <div class="relative flex justify-between items-center" v-if="!isFromLive">
              <vs-dropdown class="absolute mt-4 dd-actions cursor-pointer" vs-trigger-click>
                  <div>
                      <div class="flex px-4 items-center">
                          <svg v-if="dataCurrentRole === 'Moderator'" class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                          <svg v-else class="w-6 mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>
                          <div class="ml-2">{{ dataCurrentRole }}</div>
                          <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path fill="gray" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                      </div>
                  </div>
                  <vs-dropdown-menu>
                      <!-- Moderator -->
                      <vs-dropdown-item>
                          <div @click="dataCurrentRole = 'Moderator'" class="flex items-center moderator-menu">
                              <div>
                                  <svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-circle</title><path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" /></svg>
                              </div>
                              <div class="ml-2">{{ $t('Moderator') }}</div>
                          </div>
                      </vs-dropdown-item>
                      <!-- Possibly -->
                      <vs-dropdown-item>
                          <div @click="dataCurrentRole = 'Anonymous'" class="flex items-center moderator-menu">
                              <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                              <div class="ml-2">{{ $t('Anonymous') }}</div>
                          </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="handleClickCustom">
                          <div  class="flex items-center moderator-menu">
                              <!-- <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div> -->
                              <span class="flex" v-if="!isCustomRole">
                                <div v-if="!dataCustomCurrentRole" class="ml-2">+</div>
                                <div v-if="!dataCustomCurrentRole" style="margin-left: 12px" class="italic">{{ $t('Add new') }}</div>
                                <div class="flex items-center w-full justify-between" v-if="dataCustomCurrentRole">
                                  <div class="flex items-center w-full">
                                    <div><svg class="w-6 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account-circle</title><path fill="grey" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg></div>
                                    <div class="ml-2 truncate" style="max-width: 120px;">{{ dataCustomCurrentRole }}</div>
                                  </div>
                                  <div @click.stop="isCustomRole = true" class="ml-2 absolute right-0 pr-4  ">
                                    <svg class="w-5 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="grey" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                                  </div>
                                </div>
                              </span>
                              <div v-if="isCustomRole">
                                <div style="" class="italic focus:text-black">
                                  <vs-input
                                    class="w-full pb-2"
                                    name="name"
                                    v-model="dataCustomCurrentRole"
                                    v-validate="'required'"
                                    data-vv-validate-on="blur"
                                    @blur="handleCustomRole"
                                    @keyup.enter="handleCustomRole"
                                  />
                                </div>
                              </div>
                          </div>
                      </vs-dropdown-item>
                  </vs-dropdown-menu>
              </vs-dropdown>
              <!-- send button -->
              <div class="absolute right-0 mt-5 mr-1 px-3 py-2">
                  <div class="flex items-center">
                      <div class="mr-4">{{ characterCount }}</div>
                      <vs-button
                          :class="{ 'is-loading': isSending }"
                          class="px-3 py-2"
                          :disabled="!isFormValid"
                          @click="submitData">
                          <div class="flex items-center">
                              {{ $t('Send') }}
                              <svg class="w-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                          </div>
                      </vs-button>
                  </div>
              </div>
          </div>
          <!-- dropdown user on live side -->
          <div class="relative flex justify-between items-center" v-if="isFromLive">
              <div div class="absolute left-0 mt-5 mr-1 px-3 py-2">
                <user-selector
                  :is-show-voting-as-label="false"
                  :event="eventData"
                  @setSelectedUser="setSelectedUser"
                  >
                </user-selector>
              </div>
              <!-- send button -->
              <div class="absolute right-0 mt-5 mr-1 px-3 py-2">
                  <div class="flex items-center">
                      <div class="mr-4">{{ characterCount }}</div>
                      <vs-button
                          :class="{ 'is-loading': isSending }"
                          class="px-3 py-2"
                          :disabled="!isFormValid"
                          @click="replyFromLive">
                          <div class="flex items-center">
                              {{ $t('Send') }}
                              <svg class="w-6 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>arrow-right</title><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
                          </div>
                      </vs-button>
                  </div>
              </div>
          </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Set the locale to English
import relativeTime from 'dayjs/plugin/relativeTime';
import questionsApi from '@/api/question';
import UserSelector from '@/components/sender/UserSelector.vue';


import {
  // duplicateVar,
  getAxiosErrorMessage,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';
import { mapGetters } from 'vuex';
import noLocale from '@/lib/locale/no';
// Import English locale
dayjs.locale('en');

dayjs.extend(relativeTime);
export default {
  components: {
    UserSelector,
  },
  props: {
    isShowReplies: {
      type: Boolean,
      default: () => false,
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    isFromLive: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Object,
      default: () => null,
    },
    parentQuestion: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    isShowReplies() {
      if (this.isShowReplies) {
        this.isModalActiveLocal = true;
        this.dataPrivateReply = '';
        this.dataIsReplyEmail = false;
        this.isSending = false;
        this.dataCurrentRole = 'Moderator';
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeReplies');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      dataPrivateReply: '',
      dataIsReplyEmail: false,
      isSending: false,
      dataCurrentRole: 'Moderator',
      isCustomRole: false,
      dataCustomCurrentRole: '',

      // from live
      selectedUser: null,
    };
  },
  computed: {
    qustionAnswerInputLength() {
      let inputLength = 160;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings) inputLength = qnaSettings.input_max_length;
      return inputLength;
    },
    isFormValid() {
      return this.dataPrivateReply !== '';
    },
    exceedsLimit() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      return this.dataPrivateReply.length > maxLength; // Change 10 to your desired character count
    },
    characterCount() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      return `${this.dataPrivateReply.length}/${maxLength}`;
    },
    ...mapGetters({
      user: 'user',
    }),
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
  },
  methods: {
    handleClickCustom(event) {
      if (!this.dataCustomCurrentRole || this.isCustomRole) {
        event.stopPropagation();
      }
      this.addNewRole();
    },
    addNewRole() {
      if (!this.dataCustomCurrentRole) {
        this.isCustomRole = true;
      } else {
        this.dataCurrentRole = this.dataCustomCurrentRole;
      }
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    handleCustomRole() {
      this.dataCurrentRole = this.dataCustomCurrentRole;
      this.isCustomRole = false;
      if (!this.dataCustomCurrentRole) this.dataCurrentRole = 'Moderator';
      localStorage.setItem('CustomCurrentRole', this.dataCustomCurrentRole);
    },
    validateCharCount() {
      let maxLength = 1000;
      if (this.eventSettings) maxLength = this.qustionAnswerInputLength;
      if (this.dataPrivateReply.length > maxLength) { // Change 10 to your desired character count
        this.dataPrivateReply = this.dataPrivateReply.substring(0, maxLength); // Change 10 to your desired character count
      }
    },
    getDate(question) {
      if (this.userLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');
      return dayjs(question && question.created_at ? question.created_at : new Date()).fromNow();
    },
    submitData() {
      this.$vs.loading();
      const params = {
        name: this.dataPrivateReply,
        sender_name: this.dataCurrentRole,
        parent_id: this.selectedItem && this.selectedItem.id ? this.selectedItem.id : null,
        is_private: false,
        is_reply_to_email: this.dataIsReplyEmail,
      };
      const callback = (response) => {
        this.$emit('updateQuestion', response.data);
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        this.isModalActiveLocal = false;
        this.$vs.loading.close();
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      questionsApi.create(this.selectedItem && this.selectedItem.event_id ? this.selectedItem.event_id : null, params, callback, errorCallback);
    },

    // from live
    setSelectedUser(user) {
      this.selectedUser = user;
    },
    replyFromLive() {
      // Default
      const params = {
        sender_name: this.selectedUser && this.selectedUser.name ? this.selectedUser.name : 'Anonymous',
        name: this.dataPrivateReply,
      };

      // Parent
      if (this.selectedItem) params.parent_id = this.selectedItem.id;

      this.isSaving = true;
      this.isFetching = true;
      const callback = (response) => {
        this.isSaving = false;
        this.$emit('closeReplies', response.data);
        this.isModalActiveLocal = false;

        this.$vs.notify({
          text: this.$t('Question successfully sent'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (error) => {
        this.isSaving = false;

        // Wait 1 minute warning
        if (error.response && error.response.status === 429) {
          const message = this.$t('Please wait for a minute before replying another question');
          this.$vs.notify({
            title: this.$t('Question'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
          });
        }
      };
      questionsApi.create(this.selectedItem.event_id, params, callback, errorCallback);
    },
    starredQuestion(question) {
      this.$emit('starredQuestion', question);
    },

    publishedQuestion(question) {
      this.$emit('publishedQuestion', question);
    },

    highlightQuestion(question) {
      this.$emit('highlightQuestion', question);
    },

    editQuestion(question) {
      this.isModalActiveLocal = false;
      this.$emit('editQuestion', question);
    },
    deleteQuestion(question) {
      this.isModalActiveLocal = false;
      this.$emit('deleteQuestion', question);
      return this.dataReply !== '';
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    const CustomCurrentRole = localStorage.getItem('CustomCurrentRole');
    this.dataCustomCurrentRole = CustomCurrentRole;
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.moderation-menu {
		width: 120px;
	}
	.moderator-menu {
		width: 160px;
	}
	.private-reply.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
        @media (min-width: 800px) {
            min-width: 800px!important;
        }
    }
    .reply-section {
        margin-top: 30px;
        width: 98%;
        border: 1px solid rgb(190, 190, 190);
        min-height: 210px;
        border-radius: 6px;
    }
    .input-private-reply {
        border: none!important;
        border-bottom: 1px solid rgb(190, 190, 190)!important;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
        padding-bottom:30px;
    }
</style>
